/* You can add global styles to this file, and also import other style files */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  // margin: 0;
  // padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

::selection {
  background: rgb(78 158 110 / 1);
  color: #fff;
}

table {
  font-size: 0.89em;
}

th {
  font-weight: normal;
  // font-size: .89em;
}

html {
  font-family: Inter, Roboto, sans-serif;
  // overflow: hidden;
}

html,
body {
  height: 100%;
  font-size: 0.96rem;
}

:root {
  --color-primary: #1d3661;
  --color-primary-light: #004e92;
  --color-secondary-light: #4e9e6e;
  --color-secondary-bold: #358354;
  --color-text-modal: #707070;
}

.cl-primary {
  color: var(--color-primary);
}
.cl-primary-light {
  color: var(--color-primary-light);
}
.cl-secondary-light {
  color: var(--color-secondary-light);
}
.cl-secondary-bold {
  color: var(--color-secondary-bold);
}
.cl-text-modal {
  color: var(--color-text-modal);
}

.input {
  @apply w-full rounded-3xl text-sm px-5 py-2.5 border-[1px] border-[#e5e7eb] border-solid text-gray-600 placeholder-gray-400; 
  outline: none;
  // font-size: 15px;
  //border: 1px solid #ccc;
  height: 44.5px;
  // border-color: #e5e7eb;

  &-error {
    outline: none;
    border-width: 1px;
    @apply border-red-500;
  }
}
.input_file {
  @apply w-full rounded-3xl text-sm px-5 py-1;
  outline: none;
  border: 1px solid #ccc;
  // height: 25px;
  border-color: #e5e7eb;
  color: #9ca3af;
}

.gray {
  opacity: 0.3;
}

.loader {
  @apply animate-spin;
}

/*modal*/
.modal {
  position: fixed;
  z-index: 100;
  // padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  &__body {
    max-width: 500px;
    height: auto;
    background: #fff;
    margin: 0 auto;
    position: relative;
    // padding: 47px 69px;
    top: 100px;
    border-radius: 28px;
    // font-size: 14px;
    text-align: center;
    &__text {
      padding: 47px 88px 0;
      p {
      }
    }
  }
  &__body .btn__close {
    &__img {
      width: 28px;
      position: absolute;
      right: -10px;
      top: -20px;
      cursor: pointer;
    }
  }
  hr {
    border: 1px solid #ccc;
    width: 80%;
    margin: 12px auto;
  }
  .btn__accept {
    width: 44%;
    padding: 4;
    margin-bottom: 40px;
    padding: 8px;
    border-radius: 20px;
    font-size: 14px;
  }
}
.btn__create {
  right: -2px;
  border: 1px solid #ccc;
  top: 0px;
  padding: 13px;
}
.btn__addForm {
  right: 10px;
  border: 1px solid #ccc;
  top: 8px;
  font-size: 24px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

.back-color {
  background-color: #1d3661;
}
.bor-color {
  border-color: #e5e7eb;
}
.clight {
  color: var(--color-primary-light);
}
.bglight {
  background: var(--color-primary-light);
}
.w-24 {
  width: 24%;
}
.btn__passForm {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 24px;
  padding: 10px 15px;
  font-size: 15px;
  // height: auto;
  height: 44.5px;
}

.spinner {
  opacity: 0.7;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;

  border: 3px solid #ffffff;
  border-top-color: #63666a;
  animation: spinner 0.8s linear infinite;
}

// */BLOCK ALIGN RIGHT REPORT*/
.cell-align-right {
  display: block;
  text-align: right;
}

.border-y-line {
  width: 100%;
  border-top: 0.6px solid #cccccc54;
  border-bottom: 0.6px solid #cccccc54;
}

.text-error {
  color: #f14d4d;
  font-weight: 600;
}

.inputDocumentPersonText {
  width: 200px;
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  padding: 4px 10px;
  margin: 4px;
  margin-top: 0.5em;
}

.inputDocumentPerson::-webkit-file-upload-button {
  visibility: hidden;
}
.inputDocumentPerson::before {
  width: 200px;
  content: "Cargar documento";
  display: inline-block;
  background: var(--color-primary);
  color: white;
  border: none;
  border-radius: 15px;
  padding: 7px 10px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  margin-top: 0.5em;
  position: relative;
}

.inputDocumentPerson:hover::before {
  background: var(--color-secondary-light);
}
.inputDocumentPerson:active::before {
  background: var(--color-primary-light);
}

.display-inline {
  display: inline-block;
}

.w-100 {
  width: 100%;
}

.container-title {
  margin-top: 20px;
  position: relative;
  border-top: 1px solid #1d3661;
  border-bottom: 1px solid #1d3661;
  margin-bottom: 50px;

  h3 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 30px;
    padding: 15px 25px;
    font-weight: 600;
    color: #fff;
  }
  
}

.color-text-gray{color: #9ca3af;}


/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d3661;
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
